import { ExaminationStateSchema } from 'types/examination';

export const mockMeasurements = (): ExaminationStateSchema['measurements'] => ({
  data: {
    etdrs_calculation_zone: {
      fibrovascular_rpe_detachment: {
        areas: [
          [
            {
              mean: 69.0625,
            },
          ],
          [
            {
              mean: 124.75,
            },
            {
              mean: 12.625,
            },
            {
              mean: 14.1875,
            },
            {
              mean: 2.75,
            },
          ],
          [
            {
              mean: 700.25,
            },
            {
              mean: 151.5625,
            },
            {
              mean: 231.375,
            },
            {
              mean: 228.6875,
            },
          ],
        ],
        volumes: {
          nl: [
            [
              {
                mean: 3.345719375,
              },
            ],
            [
              {
                mean: 1.1607243125,
              },
              {
                mean: 0.0436921875,
              },
              {
                mean: 1.9675733749999997,
              },
              {
                mean: 3.0556032499999999,
              },
            ],
            [
              {
                mean: 10.83935475,
              },
              {
                mean: 6.013855625,
              },
              {
                mean: 14.3896794375,
              },
              {
                mean: 6.858051625,
              },
            ],
          ],
          mm: [
            [
              {
                mean: 0.001345719375,
              },
            ],
            [
              {
                mean: 0.0021607243125,
              },
              {
                mean: 0.0000436921875,
              },
              {
                mean: 0.0029675733749999996,
              },
              {
                mean: 0.0010556032499999999,
              },
            ],
            [
              {
                mean: 2.01383935475,
              },
              {
                mean: 1.004013855625,
              },
              {
                mean: 2.0113896794375,
              },
              {
                mean: 5.002858051625,
              },
            ],
          ],
        },
      },
      intraretinal_hyperreflective_foci: {
        areas: [
          [
            {
              mean: 235.0625,
            },
          ],
          [
            {
              mean: 14.75,
            },
            {
              mean: 22.625,
            },
            {
              mean: 171.1875,
            },
            {
              mean: 32.75,
            },
          ],
          [
            {
              mean: 745.25,
            },
            {
              mean: 271.5625,
            },
            {
              mean: 542.375,
            },
            {
              mean: 171.6875,
            },
          ],
        ],
        volumes: {
          nl: [
            [
              {
                mean: 1.345719375,
              },
            ],
            [
              {
                mean: 2.1607243125,
              },
              {
                mean: 0.0436921875,
              },
              {
                mean: 2.9675733749999997,
              },
              {
                mean: 1.0556032499999999,
              },
            ],
            [
              {
                mean: 11.83935475,
              },
              {
                mean: 3.013855625,
              },
              {
                mean: 12.3896794375,
              },
              {
                mean: 1.858051625,
              },
            ],
          ],
          mm: [
            [
              {
                mean: 0.001345719375,
              },
            ],
            [
              {
                mean: 0.0021607243125,
              },
              {
                mean: 0.0000436921875,
              },
              {
                mean: 0.0029675733749999996,
              },
              {
                mean: 0.0010556032499999999,
              },
            ],
            [
              {
                mean: 0.01383935475,
              },
              {
                mean: 0.004013855625,
              },
              {
                mean: 0.0113896794375,
              },
              {
                mean: 0.002858051625,
              },
            ],
          ],
        },
      },
      neurosensory_retina_atrophy: {
        areas: [
          [
            {
              mean: 65.0625,
            },
          ],
          [
            {
              mean: 114.75,
            },
            {
              mean: 2.625,
            },
            {
              mean: 174.1875,
            },
            {
              mean: 72.75,
            },
          ],
          [
            {
              mean: 740.25,
            },
            {
              mean: 171.5625,
            },
            {
              mean: 546.375,
            },
            {
              mean: 175.6875,
            },
          ],
        ],
        volumes: {
          nl: [
            [
              {
                mean: 1.345719375,
              },
            ],
            [
              {
                mean: 2.1607243125,
              },
              {
                mean: 0.0436921875,
              },
              {
                mean: 2.9675733749999997,
              },
              {
                mean: 1.0556032499999999,
              },
            ],
            [
              {
                mean: 13.83935475,
              },
              {
                mean: 4.013855625,
              },
              {
                mean: 11.3896794375,
              },
              {
                mean: 2.858051625,
              },
            ],
          ],
          mm: [
            [
              {
                mean: 0.001345719375,
              },
            ],
            [
              {
                mean: 0.0021607243125,
              },
              {
                mean: 0.0000436921875,
              },
              {
                mean: 0.0029675733749999996,
              },
              {
                mean: 0.0010556032499999999,
              },
            ],
            [
              {
                mean: 0.01383935475,
              },
              {
                mean: 0.004013855625,
              },
              {
                mean: 0.0113896794375,
              },
              {
                mean: 0.002858051625,
              },
            ],
          ],
        },
      },
    },
    link: 'https://api.altris-core.tallium.com/storage/uploads/examinations/63dd85d95525ff670e0d0bf2/63dd85dc8f473d3905027e37/file.005.jpg',
    thumbnail_link:
      'https://api.altris-core.tallium.com/storage/uploads/examinations/63dd85d95525ff670e0d0bf2/63dd85dc8f473d3905027e37/conversions/file.005-thumb.jpg',
    pathologies: [
      {
        className: 'fibrovascular_rpe_detachment',
        label: 'Fibrovascular RPE detachment',
        color: '#6200EA',
        opacity: '0.60',
        linear: {
          polygon_max_bb: {
            max_x: 1195.338,
            max_x_line: {
              p1: {
                x: 265,
                y: 321,
              },
              p2: {
                x: 316,
                y: 321,
              },
            },
            max_y: 125.49,
            max_y_line: {
              p1: {
                x: 290.5,
                y: 306,
              },
              p2: {
                x: 290.5,
                y: 336,
              },
            },
          },
          polygon_max_diameter: 1196.2232778260918,
        },
        'b-scan-area': 0.0942,
        points: [
          282, 306, 280, 308, 280, 309, 279, 310, 279, 311, 277, 313, 279, 315,
          279, 316, 280, 317, 280, 320, 278, 322, 277, 322, 276, 323, 274, 323,
          273, 324, 272, 324, 271, 325, 265, 325, 265, 326, 266, 326, 267, 327,
          267, 328, 268, 329, 268, 331, 269, 332, 270, 332, 274, 336, 281, 336,
          283, 334, 287, 334, 288, 333, 293, 333, 294, 332, 295, 332, 296, 331,
          297, 331, 299, 329, 303, 329, 304, 328, 306, 328, 307, 327, 309, 327,
          307, 327, 306, 326, 306, 323, 307, 322, 307, 321, 308, 320, 309, 320,
          310, 319, 311, 319, 313, 321, 314, 320, 315, 320, 315, 318, 316, 317,
          316, 315, 315, 314, 315, 312, 314, 311, 314, 308, 305, 308, 303, 310,
          299, 310, 297, 308, 294, 308, 293, 307, 289, 307, 288, 306,
        ],
        pathology_en_face_area: 6.3498,
        pathology_volume: 0.2658,
        pathologies_count: null,
        cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.2857,
          etdrs_3mm: 2.2921,
          etdrs_6mm: 5.438,
        },
        not_cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.2857,
          etdrs_3mm: 2.0064,
          etdrs_6mm: 3.1459,
        },
        cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0138',
            nl: '0.0138',
          },
          etdrs_3mm: {
            mm: '0.1231',
            nl: '0.1231',
          },
          etdrs_6mm: {
            mm: '0.2414',
            nl: '0.2414',
          },
        },
        not_cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0138',
            nl: '0.0138',
          },
          etdrs_3mm: {
            mm: '0.1093',
            nl: '0.1093',
          },
          etdrs_6mm: {
            mm: '0.1183',
            nl: '0.1183',
          },
        },
      },
      {
        className: 'fibrovascular_rpe_detachment',
        label: 'Fibrovascular RPE detachment',
        color: '#6200EA',
        opacity: '0.60',
        pathologies_count: null,
        linear: {
          polygon_max_bb: {
            max_x: 1710.974,
            max_x_line: {
              p1: {
                x: 167,
                y: 315.5,
              },
              p2: {
                x: 240,
                y: 315.5,
              },
            },
            max_y: 146.405,
            max_y_line: {
              p1: {
                x: 203.5,
                y: 298,
              },
              p2: {
                x: 203.5,
                y: 333,
              },
            },
          },
          polygon_max_diameter: 1716.8748137124032,
        },
        'b-scan-area': 0.0823,
        points: [
          219, 298, 218, 299, 217, 299, 216, 300, 216, 301, 215, 302, 215, 306,
          214, 307, 214, 308, 212, 310, 211, 310, 210, 311, 204, 311, 203, 312,
          202, 311, 199, 311, 198, 310, 196, 310, 195, 309, 195, 308, 194, 308,
          192, 306, 190, 306, 188, 304, 187, 304, 185, 302, 182, 302, 181, 301,
          178, 301, 177, 300, 173, 300, 172, 299, 167, 299, 169, 301, 169, 302,
          171, 302, 172, 303, 172, 304, 173, 305, 176, 305, 177, 306, 178, 306,
          179, 307, 181, 307, 184, 310, 187, 310, 188, 311, 191, 311, 192, 312,
          198, 312, 199, 313, 199, 314, 200, 315, 201, 315, 202, 316, 203, 316,
          204, 317, 205, 317, 209, 321, 210, 321, 211, 322, 214, 322, 215, 323,
          218, 323, 221, 326, 223, 326, 224, 327, 226, 327, 227, 328, 229, 328,
          232, 331, 234, 331, 235, 332, 236, 332, 237, 333, 240, 333, 238, 333,
          236, 331, 236, 329, 237, 328, 237, 326, 239, 324, 239, 323, 240, 322,
          240, 315, 236, 315, 234, 313, 230, 313, 229, 312, 228, 312, 227, 311,
          226, 311, 225, 310, 225, 308, 224, 307, 224, 306, 223, 305, 223, 304,
          220, 301, 220, 300, 219, 299,
        ],
        pathology_en_face_area: 6.3498,
        pathology_volume: 0.2658,

        cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.2857,
          etdrs_3mm: 2.2921,
          etdrs_6mm: 5.438,
        },
        not_cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.2857,
          etdrs_3mm: 2.0064,
          etdrs_6mm: 3.1459,
        },
        cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0138',
            nl: '0.0138',
          },
          etdrs_3mm: {
            mm: '0.1231',
            nl: '0.1231',
          },
          etdrs_6mm: {
            mm: '0.2414',
            nl: '0.2414',
          },
        },
        not_cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0138',
            nl: '0.0138',
          },
          etdrs_3mm: {
            mm: '0.1093',
            nl: '0.1093',
          },
          etdrs_6mm: {
            mm: '0.1183',
            nl: '0.1183',
          },
        },
      },
      {
        className: 'intraretinal_hyperreflective_foci',
        label: 'Intraretinal hyperreflective foci',
        color: '#00695C',
        opacity: '0.80',
        linear: {
          polygon_max_bb: {
            max_x: 117.19,
            max_x_line: {
              p1: {
                x: 299,
                y: 284,
              },
              p2: {
                x: 304,
                y: 284,
              },
            },
            max_y: 41.83,
            max_y_line: {
              p1: {
                x: 301.5,
                y: 279,
              },
              p2: {
                x: 301.5,
                y: 289,
              },
            },
          },
          polygon_max_diameter: 121.87426059673132,
        },
        'b-scan-area': 0.006,
        points: [
          300, 279, 299, 280, 299, 287, 300, 288, 300, 289, 303, 289, 304, 288,
          304, 280, 303, 279,
        ],
        pathology_en_face_area: 0.5066,
        pathology_volume: 0.0127,
        pathologies_count: null,
        cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.0,
          etdrs_3mm: 0.1977,
          etdrs_6mm: 0.4667,
        },
        not_cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.0,
          etdrs_3mm: 0.1977,
          etdrs_6mm: 0.2691,
        },
        cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0',
            nl: '0.0',
          },
          etdrs_3mm: {
            mm: '0.0055',
            nl: '0.0055',
          },
          etdrs_6mm: {
            mm: '0.0119',
            nl: '0.0119',
          },
        },
        not_cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0',
            nl: '0.0',
          },
          etdrs_3mm: {
            mm: '0.0055',
            nl: '0.0055',
          },
          etdrs_6mm: {
            mm: '0.0064',
            nl: '0.0064',
          },
        },
      },
      {
        className: 'neurosensory_retina_atrophy',
        label: 'Neurosensory retina atrophy',
        color: '#FF8602',
        opacity: '0.60',
        pathologies_count: null,
        linear: {
          polygon_max_bb: {
            max_x: 914.0820000000001,
            max_x_line: {
              p1: {
                x: 247,
                y: 286,
              },
              p2: {
                x: 286,
                y: 286,
              },
            },
            max_y: 83.66,
            max_y_line: {
              p1: {
                x: 266.5,
                y: 276,
              },
              p2: {
                x: 266.5,
                y: 296,
              },
            },
          },
          polygon_max_diameter: 914.5508622733898,
        },
        'b-scan-area': 0.0539,
        points: [
          279, 276, 277, 278, 276, 278, 275, 279, 274, 279, 273, 280, 272, 280,
          271, 281, 269, 281, 267, 283, 262, 283, 260, 281, 259, 281, 258, 280,
          257, 280, 256, 279, 254, 279, 253, 278, 251, 278, 250, 279, 249, 279,
          249, 281, 248, 282, 248, 283, 247, 284, 247, 285, 248, 286, 249, 286,
          252, 289, 253, 289, 254, 290, 255, 290, 256, 291, 257, 291, 258, 292,
          258, 293, 259, 294, 261, 294, 262, 295, 268, 295, 269, 296, 277, 296,
          278, 295, 280, 295, 281, 294, 284, 294, 285, 293, 285, 292, 286, 291,
          286, 288, 285, 287, 285, 284, 284, 283, 284, 280, 283, 279, 282, 279,
          281, 278, 281, 276,
        ],
        pathology_en_face_area: 24.8878,
        pathology_volume: 2.295,
        cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.2857,
          etdrs_3mm: 0.4601,
          etdrs_6mm: 0.6079,
        },
        not_cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.2857,
          etdrs_3mm: 0.1744,
          etdrs_6mm: 0.1478,
        },
        cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0185',
            nl: '0.0185',
          },
          etdrs_3mm: {
            mm: '0.0302',
            nl: '0.0302',
          },
          etdrs_6mm: {
            mm: '0.0348',
            nl: '0.0348',
          },
        },
        not_cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0185',
            nl: '0.0185',
          },
          etdrs_3mm: {
            mm: '0.0117',
            nl: '0.0117',
          },
          etdrs_6mm: {
            mm: '0.0046',
            nl: '0.0046',
          },
        },
      },
      {
        className: 'neurosensory_retina_atrophy',
        label: 'Neurosensory retina atrophy',
        color: '#FF8602',
        opacity: '0.60',
        pathologies_count: null,
        linear: {
          polygon_max_bb: {
            max_x: 1710.974,
            max_x_line: {
              p1: {
                x: 404,
                y: 194,
              },
              p2: {
                x: 477,
                y: 194,
              },
            },
            max_y: 284.444,
            max_y_line: {
              p1: {
                x: 440.5,
                y: 160,
              },
              p2: {
                x: 440.5,
                y: 228,
              },
            },
          },
          polygon_max_diameter: 1728.6818064308425,
        },
        'b-scan-area': 0.1453,
        points: [
          471, 160, 469, 162, 468, 162, 467, 163, 466, 163, 466, 164, 462, 168,
          462, 169, 461, 170, 461, 171, 460, 172, 459, 172, 459, 173, 453, 179,
          452, 179, 446, 185, 446, 187, 433, 200, 432, 200, 429, 203, 428, 203,
          427, 204, 426, 204, 425, 205, 424, 205, 423, 206, 422, 206, 419, 209,
          418, 209, 416, 211, 414, 211, 413, 212, 412, 212, 409, 215, 408, 215,
          404, 219, 420, 219, 425, 224, 425, 225, 426, 225, 427, 226, 427, 228,
          429, 228, 430, 227, 432, 227, 434, 225, 434, 224, 436, 222, 438, 222,
          440, 220, 441, 220, 446, 215, 446, 214, 450, 210, 450, 209, 451, 208,
          451, 206, 452, 205, 453, 205, 454, 204, 454, 203, 455, 202, 455, 201,
          457, 199, 457, 198, 459, 196, 460, 196, 460, 195, 462, 193, 462, 192,
          464, 190, 464, 189, 466, 187, 466, 185, 467, 184, 467, 183, 470, 180,
          470, 179, 472, 177, 472, 175, 475, 172, 475, 168, 476, 167, 476, 165,
          477, 164, 477, 160, 476, 161, 475, 160, 474, 161, 473, 161, 472, 160,
        ],
        pathology_en_face_area: 24.8878,
        pathology_volume: 2.295,
        cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.2857,
          etdrs_3mm: 0.4601,
          etdrs_6mm: 0.6079,
        },
        not_cumulative_etdrs_pathology_areas: {
          etdrs_1mm: 0.2857,
          etdrs_3mm: 0.1744,
          etdrs_6mm: 0.1478,
        },
        cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0185',
            nl: '0.0185',
          },
          etdrs_3mm: {
            mm: '0.0302',
            nl: '0.0302',
          },
          etdrs_6mm: {
            mm: '0.0348',
            nl: '0.0348',
          },
        },
        not_cumulative_etdrs_pathology_volumes: {
          etdrs_1mm: {
            mm: '0.0185',
            nl: '0.0185',
          },
          etdrs_3mm: {
            mm: '0.0117',
            nl: '0.0117',
          },
          etdrs_6mm: {
            mm: '0.0046',
            nl: '0.0046',
          },
        },
      },
    ],
    thickness_maps: {
      fibrovascular_rpe_detachment: [
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 4.183, 4.183, 12.549, 16.732, 20.915, 29.281, 33.464, 33.464,
          33.464, 37.647, 33.464, 37.647, 37.647, 37.647, 37.647, 37.647,
          37.647, 33.464, 33.464, 33.464, 33.464, 33.464, 33.464, 33.464,
          33.464, 33.464, 33.464, 37.647, 33.464, 33.464, 33.464, 25.098, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732,
          25.098, 33.464, 37.647, 41.83, 41.83, 41.83, 41.83, 46.013, 41.83,
          41.83, 41.83, 46.013, 46.013, 41.83, 41.83, 46.013, 41.83, 37.647,
          37.647, 33.464, 37.647, 33.464, 33.464, 29.281, 20.915, 16.732,
          12.549, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        Array(512).fill(0),
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          29.281, 33.464, 37.647, 37.647, 37.647, 37.647, 33.464, 33.464,
          37.647, 41.83, 41.83, 46.013, 46.013, 46.013, 46.013, 46.013, 50.196,
          46.013, 50.196, 50.196, 50.196, 54.379, 50.196, 54.379, 54.379,
          50.196, 54.379, 58.562, 62.745, 62.745, 62.745, 62.745, 58.562,
          54.379, 58.562, 50.196, 46.013, 46.013, 41.83, 33.464, 29.281, 29.281,
          25.098, 25.098, 20.915, 20.915, 20.915, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732, 20.915, 25.098, 29.281,
          33.464, 33.464, 33.464, 33.464, 33.464, 33.464, 37.647, 37.647,
          37.647, 37.647, 41.83, 41.83, 41.83, 50.196, 50.196, 41.83, 41.83,
          37.647, 46.013, 46.013, 66.928, 66.928, 66.928, 75.294, 75.294,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 75.294, 71.11099999999999,
          66.928, 62.745, 54.379, 37.647, 29.281, 25.098, 20.915, 16.732, 8.366,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 25.098, 33.464, 33.464, 37.647, 37.647,
          37.647, 37.647, 37.647, 37.647, 37.647, 37.647, 37.647, 33.464,
          33.464, 37.647, 41.83, 41.83, 41.83, 41.83, 41.83, 41.83, 46.013,
          46.013, 41.83, 46.013, 50.196, 50.196, 50.196, 50.196, 54.379, 58.562,
          50.196, 54.379, 54.379, 54.379, 58.562, 54.379, 50.196, 50.196,
          50.196, 50.196, 50.196, 50.196, 46.013, 41.83, 37.647, 33.464, 29.281,
          29.281, 25.098, 20.915, 16.732, 12.549, 8.366, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732,
          20.915, 25.098, 25.098, 25.098, 25.098, 29.281, 25.098, 29.281,
          33.464, 37.647, 37.647, 33.464, 37.647, 37.647, 41.83, 41.83, 41.83,
          41.83, 46.013, 46.013, 50.196, 54.379, 58.562, 66.928, 66.928,
          71.11099999999999, 71.11099999999999, 71.11099999999999, 75.294,
          75.294, 75.294, 75.294, 71.11099999999999, 71.11099999999999, 62.745,
          62.745, 58.562, 54.379, 54.379, 54.379, 54.379, 50.196, 50.196,
          54.379, 58.562, 58.562, 58.562, 58.562, 58.562, 58.562, 58.562,
          58.562, 54.379, 50.196, 50.196, 50.196, 54.379, 54.379, 54.379,
          58.562, 62.745, 62.745, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 75.294, 62.745, 62.745, 58.562, 54.379, 54.379,
          54.379, 50.196, 50.196, 50.196, 50.196, 50.196, 50.196, 54.379,
          54.379, 54.379, 50.196, 46.013, 46.013, 37.647, 29.281, 25.098,
          33.464, 58.562, 79.477, 79.477, 79.477, 79.477, 71.11099999999999,
          71.11099999999999, 66.928, 66.928, 54.379, 41.83, 29.281, 25.098,
          20.915, 12.549, 4.183, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4.183,
          20.915, 41.83, 46.013, 46.013, 46.013, 41.83, 41.83, 41.83, 41.83,
          41.83, 41.83, 41.83, 41.83, 46.013, 46.013, 46.013, 41.83, 46.013,
          50.196, 54.379, 54.379, 54.379, 54.379, 58.562, 62.745,
          71.11099999999999, 75.294, 79.477, 83.66, 83.66, 66.928, 62.745,
          29.281, 25.098, 25.098, 20.915, 20.915, 20.915, 16.732, 8.366, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 8.366, 12.549, 12.549, 16.732, 20.915, 25.098, 29.281,
          33.464, 33.464, 33.464, 33.464, 37.647, 33.464, 33.464, 33.464,
          37.647, 37.647, 41.83, 41.83, 41.83, 46.013, 46.013, 46.013, 50.196,
          50.196, 54.379, 58.562, 54.379, 62.745, 62.745, 62.745, 66.928,
          71.11099999999999, 75.294, 79.477, 79.477, 79.477, 83.66, 83.66,
          83.66, 83.66, 83.66, 83.66, 83.66, 87.84299999999999,
          87.84299999999999, 87.84299999999999, 83.66, 83.66, 79.477, 79.477,
          79.477, 75.294, 75.294, 79.477, 83.66, 83.66, 83.66, 41.83, 41.83,
          41.83, 58.562, 58.562, 62.745, 62.745, 62.745, 79.477, 79.477, 79.477,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 62.745, 62.745, 66.928, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 66.928, 66.928, 41.83, 25.098,
          25.098, 16.732, 12.549, 8.366, 8.366, 8.366, 8.366, 25.098, 37.647,
          62.745, 62.745, 62.745, 54.379, 54.379, 54.379, 54.379, 54.379,
          54.379, 50.196, 50.196, 41.83, 37.647, 33.464, 33.464, 29.281, 20.915,
          12.549, 4.183, 12.549, 20.915, 20.915, 25.098, 29.281, 29.281, 33.464,
          37.647, 41.83, 41.83, 41.83, 46.013, 46.013, 46.013, 46.013, 46.013,
          50.196, 50.196, 54.379, 54.379, 54.379, 54.379, 54.379, 46.013,
          46.013, 46.013, 46.013, 46.013, 41.83, 37.647, 37.647, 37.647, 37.647,
          33.464, 33.464, 29.281, 29.281, 29.281, 25.098, 29.281, 29.281,
          33.464, 37.647, 33.464, 33.464, 33.464, 33.464, 29.281, 29.281,
          29.281, 25.098, 20.915, 20.915, 20.915, 16.732, 4.183, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16.732,
          20.915, 20.915, 25.098, 29.281, 20.915, 20.915, 20.915, 20.915, 4.183,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 8.366, 12.549, 16.732, 20.915, 25.098, 29.281, 29.281, 29.281,
          33.464, 37.647, 41.83, 46.013, 46.013, 46.013, 46.013, 46.013, 46.013,
          46.013, 50.196, 50.196, 54.379, 54.379, 58.562, 62.745, 62.745,
          62.745, 62.745, 62.745, 58.562, 54.379, 58.562, 62.745, 66.928,
          66.928, 71.11099999999999, 79.477, 83.66, 83.66, 83.66,
          87.84299999999999, 92.026, 92.026, 92.026, 96.209, 96.209, 100.392,
          100.392, 100.392, 96.209, 87.84299999999999, 83.66, 83.66, 83.66,
          83.66, 83.66, 75.294, 58.562, 46.013, 33.464, 29.281, 29.281, 29.281,
          25.098, 4.183, 4.183, 4.183, 4.183, 4.183, 4.183, 4.183, 4.183, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 8.366, 20.915, 20.915, 8.366, 8.366, 12.549,
          12.549, 25.098, 29.281, 29.281, 29.281, 29.281, 29.281, 33.464,
          46.013, 50.196, 54.379, 50.196, 50.196, 46.013, 29.281, 20.915,
          20.915, 16.732, 12.549, 33.464, 50.196, 50.196, 50.196, 50.196,
          50.196, 50.196, 50.196, 50.196, 41.83, 41.83, 37.647, 33.464, 33.464,
          33.464, 29.281, 29.281, 25.098, 41.83, 46.013, 46.013, 46.013, 46.013,
          50.196, 50.196, 54.379, 54.379, 54.379, 54.379, 58.562, 58.562,
          58.562, 58.562, 54.379, 46.013, 46.013, 37.647, 37.647, 12.549,
          12.549, 16.732, 20.915, 25.098, 25.098, 25.098, 29.281, 41.83, 41.83,
          25.098, 8.366, 12.549, 16.732, 25.098, 25.098, 29.281, 29.281, 33.464,
          33.464, 37.647, 37.647, 33.464, 29.281, 25.098, 25.098, 20.915,
          12.549, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 20.915, 20.915, 33.464, 33.464,
          41.83, 46.013, 46.013, 46.013, 50.196, 50.196, 50.196, 50.196, 54.379,
          58.562, 62.745, 62.745, 66.928, 66.928, 66.928, 62.745, 66.928,
          66.928, 66.928, 66.928, 71.11099999999999, 92.026, 96.209, 96.209,
          100.392, 104.57499999999999, 96.209, 87.84299999999999, 79.477,
          71.11099999999999, 4.183, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8.366, 25.098, 25.098, 33.464,
          37.647, 41.83, 41.83, 46.013, 50.196, 50.196, 54.379, 62.745, 62.745,
          62.745, 66.928, 66.928, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 75.294, 79.477, 83.66, 83.66,
          87.84299999999999, 87.84299999999999, 96.209, 96.209, 96.209, 96.209,
          96.209, 96.209, 96.209, 92.026, 92.026, 87.84299999999999, 92.026,
          92.026, 87.84299999999999, 83.66, 75.294, 58.562, 41.83, 37.647,
          33.464, 29.281, 25.098, 20.915, 20.915, 20.915, 20.915, 12.549, 4.183,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4.183,
          12.549, 12.549, 12.549, 16.732, 16.732, 16.732, 16.732, 16.732,
          16.732, 16.732, 25.098, 25.098, 25.098, 29.281, 33.464, 41.83, 41.83,
          41.83, 41.83, 37.647, 37.647, 33.464, 33.464, 33.464, 37.647, 37.647,
          33.464, 29.281, 29.281, 29.281, 29.281, 29.281, 25.098, 25.098,
          20.915, 20.915, 12.549, 12.549, 8.366, 8.366, 4.183, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732, 20.915, 20.915, 20.915,
          29.281, 29.281, 29.281, 33.464, 37.647, 37.647, 41.83, 46.013, 50.196,
          54.379, 58.562, 58.562, 58.562, 62.745, 62.745, 66.928,
          71.11099999999999, 71.11099999999999, 71.11099999999999, 79.477,
          79.477, 83.66, 83.66, 87.84299999999999, 92.026, 96.209, 58.562,
          46.013, 20.915, 8.366, 8.366, 4.183, 4.183, 4.183, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12.549, 20.915, 25.098, 29.281,
          25.098, 20.915, 16.732, 16.732, 16.732, 16.732, 20.915, 25.098,
          20.915, 20.915, 16.732, 12.549, 12.549, 4.183, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
      ],
      intraretinal_hyperreflective_foci: [
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 4.183, 4.183, 12.549, 16.732, 20.915, 29.281, 33.464, 33.464,
          33.464, 37.647, 33.464, 37.647, 37.647, 37.647, 37.647, 37.647,
          37.647, 33.464, 33.464, 33.464, 33.464, 33.464, 33.464, 33.464,
          33.464, 33.464, 33.464, 37.647, 33.464, 33.464, 33.464, 25.098, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732,
          25.098, 33.464, 37.647, 41.83, 41.83, 41.83, 41.83, 46.013, 41.83,
          41.83, 41.83, 46.013, 46.013, 41.83, 41.83, 46.013, 41.83, 37.647,
          37.647, 33.464, 37.647, 33.464, 33.464, 29.281, 20.915, 16.732,
          12.549, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        Array(512).fill(0),
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          29.281, 33.464, 37.647, 37.647, 37.647, 37.647, 33.464, 33.464,
          37.647, 41.83, 41.83, 46.013, 46.013, 46.013, 46.013, 46.013, 50.196,
          46.013, 50.196, 50.196, 50.196, 54.379, 50.196, 54.379, 54.379,
          50.196, 54.379, 58.562, 62.745, 62.745, 62.745, 62.745, 58.562,
          54.379, 58.562, 50.196, 46.013, 46.013, 41.83, 33.464, 29.281, 29.281,
          25.098, 25.098, 20.915, 20.915, 20.915, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732, 20.915, 25.098, 29.281,
          33.464, 33.464, 33.464, 33.464, 33.464, 33.464, 37.647, 37.647,
          37.647, 37.647, 41.83, 41.83, 41.83, 50.196, 50.196, 41.83, 41.83,
          37.647, 46.013, 46.013, 66.928, 66.928, 66.928, 75.294, 75.294,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 75.294, 71.11099999999999,
          66.928, 62.745, 54.379, 37.647, 29.281, 25.098, 20.915, 16.732, 8.366,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 25.098, 33.464, 33.464, 37.647, 37.647,
          37.647, 37.647, 37.647, 37.647, 37.647, 37.647, 37.647, 33.464,
          33.464, 37.647, 41.83, 41.83, 41.83, 41.83, 41.83, 41.83, 46.013,
          46.013, 41.83, 46.013, 50.196, 50.196, 50.196, 50.196, 54.379, 58.562,
          50.196, 54.379, 54.379, 54.379, 58.562, 54.379, 50.196, 50.196,
          50.196, 50.196, 50.196, 50.196, 46.013, 41.83, 37.647, 33.464, 29.281,
          29.281, 25.098, 20.915, 16.732, 12.549, 8.366, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732,
          20.915, 25.098, 25.098, 25.098, 25.098, 29.281, 25.098, 29.281,
          33.464, 37.647, 37.647, 33.464, 37.647, 37.647, 41.83, 41.83, 41.83,
          41.83, 46.013, 46.013, 50.196, 54.379, 58.562, 66.928, 66.928,
          71.11099999999999, 71.11099999999999, 71.11099999999999, 75.294,
          75.294, 75.294, 75.294, 71.11099999999999, 71.11099999999999, 62.745,
          62.745, 58.562, 54.379, 54.379, 54.379, 54.379, 50.196, 50.196,
          54.379, 58.562, 58.562, 58.562, 58.562, 58.562, 58.562, 58.562,
          58.562, 54.379, 50.196, 50.196, 50.196, 54.379, 54.379, 54.379,
          58.562, 62.745, 62.745, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 75.294, 62.745, 62.745, 58.562, 54.379, 54.379,
          54.379, 50.196, 50.196, 50.196, 50.196, 50.196, 50.196, 54.379,
          54.379, 54.379, 50.196, 46.013, 46.013, 37.647, 29.281, 25.098,
          33.464, 58.562, 79.477, 79.477, 79.477, 79.477, 71.11099999999999,
          71.11099999999999, 66.928, 66.928, 54.379, 41.83, 29.281, 25.098,
          20.915, 12.549, 4.183, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4.183,
          20.915, 41.83, 46.013, 46.013, 46.013, 41.83, 41.83, 41.83, 41.83,
          41.83, 41.83, 41.83, 41.83, 46.013, 46.013, 46.013, 41.83, 46.013,
          50.196, 54.379, 54.379, 54.379, 54.379, 58.562, 62.745,
          71.11099999999999, 75.294, 79.477, 83.66, 83.66, 66.928, 62.745,
          29.281, 25.098, 25.098, 20.915, 20.915, 20.915, 16.732, 8.366, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 8.366, 12.549, 12.549, 16.732, 20.915, 25.098, 29.281,
          33.464, 33.464, 33.464, 33.464, 37.647, 33.464, 33.464, 33.464,
          37.647, 37.647, 41.83, 41.83, 41.83, 46.013, 46.013, 46.013, 50.196,
          50.196, 54.379, 58.562, 54.379, 62.745, 62.745, 62.745, 66.928,
          71.11099999999999, 75.294, 79.477, 79.477, 79.477, 83.66, 83.66,
          83.66, 83.66, 83.66, 83.66, 83.66, 87.84299999999999,
          87.84299999999999, 87.84299999999999, 83.66, 83.66, 79.477, 79.477,
          79.477, 75.294, 75.294, 79.477, 83.66, 83.66, 83.66, 41.83, 41.83,
          41.83, 58.562, 58.562, 62.745, 62.745, 62.745, 79.477, 79.477, 79.477,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 62.745, 62.745, 66.928, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 66.928, 66.928, 41.83, 25.098,
          25.098, 16.732, 12.549, 8.366, 8.366, 8.366, 8.366, 25.098, 37.647,
          62.745, 62.745, 62.745, 54.379, 54.379, 54.379, 54.379, 54.379,
          54.379, 50.196, 50.196, 41.83, 37.647, 33.464, 33.464, 29.281, 20.915,
          12.549, 4.183, 12.549, 20.915, 20.915, 25.098, 29.281, 29.281, 33.464,
          37.647, 41.83, 41.83, 41.83, 46.013, 46.013, 46.013, 46.013, 46.013,
          50.196, 50.196, 54.379, 54.379, 54.379, 54.379, 54.379, 46.013,
          46.013, 46.013, 46.013, 46.013, 41.83, 37.647, 37.647, 37.647, 37.647,
          33.464, 33.464, 29.281, 29.281, 29.281, 25.098, 29.281, 29.281,
          33.464, 37.647, 33.464, 33.464, 33.464, 33.464, 29.281, 29.281,
          29.281, 25.098, 20.915, 20.915, 20.915, 16.732, 4.183, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16.732,
          20.915, 20.915, 25.098, 29.281, 20.915, 20.915, 20.915, 20.915, 4.183,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 8.366, 12.549, 16.732, 20.915, 25.098, 29.281, 29.281, 29.281,
          33.464, 37.647, 41.83, 46.013, 46.013, 46.013, 46.013, 46.013, 46.013,
          46.013, 50.196, 50.196, 54.379, 54.379, 58.562, 62.745, 62.745,
          62.745, 62.745, 62.745, 58.562, 54.379, 58.562, 62.745, 66.928,
          66.928, 71.11099999999999, 79.477, 83.66, 83.66, 83.66,
          87.84299999999999, 92.026, 92.026, 92.026, 96.209, 96.209, 100.392,
          100.392, 100.392, 96.209, 87.84299999999999, 83.66, 83.66, 83.66,
          83.66, 83.66, 75.294, 58.562, 46.013, 33.464, 29.281, 29.281, 29.281,
          25.098, 4.183, 4.183, 4.183, 4.183, 4.183, 4.183, 4.183, 4.183, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 8.366, 20.915, 20.915, 8.366, 8.366, 12.549,
          12.549, 25.098, 29.281, 29.281, 29.281, 29.281, 29.281, 33.464,
          46.013, 50.196, 54.379, 50.196, 50.196, 46.013, 29.281, 20.915,
          20.915, 16.732, 12.549, 33.464, 50.196, 50.196, 50.196, 50.196,
          50.196, 50.196, 50.196, 50.196, 41.83, 41.83, 37.647, 33.464, 33.464,
          33.464, 29.281, 29.281, 25.098, 41.83, 46.013, 46.013, 46.013, 46.013,
          50.196, 50.196, 54.379, 54.379, 54.379, 54.379, 58.562, 58.562,
          58.562, 58.562, 54.379, 46.013, 46.013, 37.647, 37.647, 12.549,
          12.549, 16.732, 20.915, 25.098, 25.098, 25.098, 29.281, 41.83, 41.83,
          25.098, 8.366, 12.549, 16.732, 25.098, 25.098, 29.281, 29.281, 33.464,
          33.464, 37.647, 37.647, 33.464, 29.281, 25.098, 25.098, 20.915,
          12.549, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 20.915, 20.915, 33.464, 33.464,
          41.83, 46.013, 46.013, 46.013, 50.196, 50.196, 50.196, 50.196, 54.379,
          58.562, 62.745, 62.745, 66.928, 66.928, 66.928, 62.745, 66.928,
          66.928, 66.928, 66.928, 71.11099999999999, 92.026, 96.209, 96.209,
          100.392, 104.57499999999999, 96.209, 87.84299999999999, 79.477,
          71.11099999999999, 4.183, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8.366, 25.098, 25.098, 33.464,
          37.647, 41.83, 41.83, 46.013, 50.196, 50.196, 54.379, 62.745, 62.745,
          62.745, 66.928, 66.928, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 75.294, 79.477, 83.66, 83.66,
          87.84299999999999, 87.84299999999999, 96.209, 96.209, 96.209, 96.209,
          96.209, 96.209, 96.209, 92.026, 92.026, 87.84299999999999, 92.026,
          92.026, 87.84299999999999, 83.66, 75.294, 58.562, 41.83, 37.647,
          33.464, 29.281, 25.098, 20.915, 20.915, 20.915, 20.915, 12.549, 4.183,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4.183,
          12.549, 12.549, 12.549, 16.732, 16.732, 16.732, 16.732, 16.732,
          16.732, 16.732, 25.098, 25.098, 25.098, 29.281, 33.464, 41.83, 41.83,
          41.83, 41.83, 37.647, 37.647, 33.464, 33.464, 33.464, 37.647, 37.647,
          33.464, 29.281, 29.281, 29.281, 29.281, 29.281, 25.098, 25.098,
          20.915, 20.915, 12.549, 12.549, 8.366, 8.366, 4.183, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732, 20.915, 20.915, 20.915,
          29.281, 29.281, 29.281, 33.464, 37.647, 37.647, 41.83, 46.013, 50.196,
          54.379, 58.562, 58.562, 58.562, 62.745, 62.745, 66.928,
          71.11099999999999, 71.11099999999999, 71.11099999999999, 79.477,
          79.477, 83.66, 83.66, 87.84299999999999, 92.026, 96.209, 58.562,
          46.013, 20.915, 8.366, 8.366, 4.183, 4.183, 4.183, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12.549, 20.915, 25.098, 29.281,
          25.098, 20.915, 16.732, 16.732, 16.732, 16.732, 20.915, 25.098,
          20.915, 20.915, 16.732, 12.549, 12.549, 4.183, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
      ],
      neurosensory_retina_atrophy: [
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),
        Array(512).fill(0),

        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 4.183, 4.183, 12.549, 16.732, 20.915, 29.281, 33.464, 33.464,
          33.464, 37.647, 33.464, 37.647, 37.647, 37.647, 37.647, 37.647,
          37.647, 33.464, 33.464, 33.464, 33.464, 33.464, 33.464, 33.464,
          33.464, 33.464, 33.464, 37.647, 33.464, 33.464, 33.464, 25.098, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732,
          25.098, 33.464, 37.647, 41.83, 41.83, 41.83, 41.83, 46.013, 41.83,
          41.83, 41.83, 46.013, 46.013, 41.83, 41.83, 46.013, 41.83, 37.647,
          37.647, 33.464, 37.647, 33.464, 33.464, 29.281, 20.915, 16.732,
          12.549, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        Array(512).fill(0),
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          29.281, 33.464, 37.647, 37.647, 37.647, 37.647, 33.464, 33.464,
          37.647, 41.83, 41.83, 46.013, 46.013, 46.013, 46.013, 46.013, 50.196,
          46.013, 50.196, 50.196, 50.196, 54.379, 50.196, 54.379, 54.379,
          50.196, 54.379, 58.562, 62.745, 62.745, 62.745, 62.745, 58.562,
          54.379, 58.562, 50.196, 46.013, 46.013, 41.83, 33.464, 29.281, 29.281,
          25.098, 25.098, 20.915, 20.915, 20.915, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732, 20.915, 25.098, 29.281,
          33.464, 33.464, 33.464, 33.464, 33.464, 33.464, 37.647, 37.647,
          37.647, 37.647, 41.83, 41.83, 41.83, 50.196, 50.196, 41.83, 41.83,
          37.647, 46.013, 46.013, 66.928, 66.928, 66.928, 75.294, 75.294,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 75.294, 71.11099999999999,
          66.928, 62.745, 54.379, 37.647, 29.281, 25.098, 20.915, 16.732, 8.366,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 25.098, 33.464, 33.464, 37.647, 37.647,
          37.647, 37.647, 37.647, 37.647, 37.647, 37.647, 37.647, 33.464,
          33.464, 37.647, 41.83, 41.83, 41.83, 41.83, 41.83, 41.83, 46.013,
          46.013, 41.83, 46.013, 50.196, 50.196, 50.196, 50.196, 54.379, 58.562,
          50.196, 54.379, 54.379, 54.379, 58.562, 54.379, 50.196, 50.196,
          50.196, 50.196, 50.196, 50.196, 46.013, 41.83, 37.647, 33.464, 29.281,
          29.281, 25.098, 20.915, 16.732, 12.549, 8.366, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732,
          20.915, 25.098, 25.098, 25.098, 25.098, 29.281, 25.098, 29.281,
          33.464, 37.647, 37.647, 33.464, 37.647, 37.647, 41.83, 41.83, 41.83,
          41.83, 46.013, 46.013, 50.196, 54.379, 58.562, 66.928, 66.928,
          71.11099999999999, 71.11099999999999, 71.11099999999999, 75.294,
          75.294, 75.294, 75.294, 71.11099999999999, 71.11099999999999, 62.745,
          62.745, 58.562, 54.379, 54.379, 54.379, 54.379, 50.196, 50.196,
          54.379, 58.562, 58.562, 58.562, 58.562, 58.562, 58.562, 58.562,
          58.562, 54.379, 50.196, 50.196, 50.196, 54.379, 54.379, 54.379,
          58.562, 62.745, 62.745, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 75.294, 62.745, 62.745, 58.562, 54.379, 54.379,
          54.379, 50.196, 50.196, 50.196, 50.196, 50.196, 50.196, 54.379,
          54.379, 54.379, 50.196, 46.013, 46.013, 37.647, 29.281, 25.098,
          33.464, 58.562, 79.477, 79.477, 79.477, 79.477, 71.11099999999999,
          71.11099999999999, 66.928, 66.928, 54.379, 41.83, 29.281, 25.098,
          20.915, 12.549, 4.183, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4.183,
          20.915, 41.83, 46.013, 46.013, 46.013, 41.83, 41.83, 41.83, 41.83,
          41.83, 41.83, 41.83, 41.83, 46.013, 46.013, 46.013, 41.83, 46.013,
          50.196, 54.379, 54.379, 54.379, 54.379, 58.562, 62.745,
          71.11099999999999, 75.294, 79.477, 83.66, 83.66, 66.928, 62.745,
          29.281, 25.098, 25.098, 20.915, 20.915, 20.915, 16.732, 8.366, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 8.366, 12.549, 12.549, 16.732, 20.915, 25.098, 29.281,
          33.464, 33.464, 33.464, 33.464, 37.647, 33.464, 33.464, 33.464,
          37.647, 37.647, 41.83, 41.83, 41.83, 46.013, 46.013, 46.013, 50.196,
          50.196, 54.379, 58.562, 54.379, 62.745, 62.745, 62.745, 66.928,
          71.11099999999999, 75.294, 79.477, 79.477, 79.477, 83.66, 83.66,
          83.66, 83.66, 83.66, 83.66, 83.66, 87.84299999999999,
          87.84299999999999, 87.84299999999999, 83.66, 83.66, 79.477, 79.477,
          79.477, 75.294, 75.294, 79.477, 83.66, 83.66, 83.66, 41.83, 41.83,
          41.83, 58.562, 58.562, 62.745, 62.745, 62.745, 79.477, 79.477, 79.477,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 62.745, 62.745, 66.928, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 66.928, 66.928, 41.83, 25.098,
          25.098, 16.732, 12.549, 8.366, 8.366, 8.366, 8.366, 25.098, 37.647,
          62.745, 62.745, 62.745, 54.379, 54.379, 54.379, 54.379, 54.379,
          54.379, 50.196, 50.196, 41.83, 37.647, 33.464, 33.464, 29.281, 20.915,
          12.549, 4.183, 12.549, 20.915, 20.915, 25.098, 29.281, 29.281, 33.464,
          37.647, 41.83, 41.83, 41.83, 46.013, 46.013, 46.013, 46.013, 46.013,
          50.196, 50.196, 54.379, 54.379, 54.379, 54.379, 54.379, 46.013,
          46.013, 46.013, 46.013, 46.013, 41.83, 37.647, 37.647, 37.647, 37.647,
          33.464, 33.464, 29.281, 29.281, 29.281, 25.098, 29.281, 29.281,
          33.464, 37.647, 33.464, 33.464, 33.464, 33.464, 29.281, 29.281,
          29.281, 25.098, 20.915, 20.915, 20.915, 16.732, 4.183, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16.732,
          20.915, 20.915, 25.098, 29.281, 20.915, 20.915, 20.915, 20.915, 4.183,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 8.366, 12.549, 16.732, 20.915, 25.098, 29.281, 29.281, 29.281,
          33.464, 37.647, 41.83, 46.013, 46.013, 46.013, 46.013, 46.013, 46.013,
          46.013, 50.196, 50.196, 54.379, 54.379, 58.562, 62.745, 62.745,
          62.745, 62.745, 62.745, 58.562, 54.379, 58.562, 62.745, 66.928,
          66.928, 71.11099999999999, 79.477, 83.66, 83.66, 83.66,
          87.84299999999999, 92.026, 92.026, 92.026, 96.209, 96.209, 100.392,
          100.392, 100.392, 96.209, 87.84299999999999, 83.66, 83.66, 83.66,
          83.66, 83.66, 75.294, 58.562, 46.013, 33.464, 29.281, 29.281, 29.281,
          25.098, 4.183, 4.183, 4.183, 4.183, 4.183, 4.183, 4.183, 4.183, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 8.366, 20.915, 20.915, 8.366, 8.366, 12.549,
          12.549, 25.098, 29.281, 29.281, 29.281, 29.281, 29.281, 33.464,
          46.013, 50.196, 54.379, 50.196, 50.196, 46.013, 29.281, 20.915,
          20.915, 16.732, 12.549, 33.464, 50.196, 50.196, 50.196, 50.196,
          50.196, 50.196, 50.196, 50.196, 41.83, 41.83, 37.647, 33.464, 33.464,
          33.464, 29.281, 29.281, 25.098, 41.83, 46.013, 46.013, 46.013, 46.013,
          50.196, 50.196, 54.379, 54.379, 54.379, 54.379, 58.562, 58.562,
          58.562, 58.562, 54.379, 46.013, 46.013, 37.647, 37.647, 12.549,
          12.549, 16.732, 20.915, 25.098, 25.098, 25.098, 29.281, 41.83, 41.83,
          25.098, 8.366, 12.549, 16.732, 25.098, 25.098, 29.281, 29.281, 33.464,
          33.464, 37.647, 37.647, 33.464, 29.281, 25.098, 25.098, 20.915,
          12.549, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 20.915, 20.915, 33.464, 33.464,
          41.83, 46.013, 46.013, 46.013, 50.196, 50.196, 50.196, 50.196, 54.379,
          58.562, 62.745, 62.745, 66.928, 66.928, 66.928, 62.745, 66.928,
          66.928, 66.928, 66.928, 71.11099999999999, 92.026, 96.209, 96.209,
          100.392, 104.57499999999999, 96.209, 87.84299999999999, 79.477,
          71.11099999999999, 4.183, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8.366, 25.098, 25.098, 33.464,
          37.647, 41.83, 41.83, 46.013, 50.196, 50.196, 54.379, 62.745, 62.745,
          62.745, 66.928, 66.928, 71.11099999999999, 71.11099999999999,
          71.11099999999999, 71.11099999999999, 75.294, 79.477, 83.66, 83.66,
          87.84299999999999, 87.84299999999999, 96.209, 96.209, 96.209, 96.209,
          96.209, 96.209, 96.209, 92.026, 92.026, 87.84299999999999, 92.026,
          92.026, 87.84299999999999, 83.66, 75.294, 58.562, 41.83, 37.647,
          33.464, 29.281, 25.098, 20.915, 20.915, 20.915, 20.915, 12.549, 4.183,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4.183,
          12.549, 12.549, 12.549, 16.732, 16.732, 16.732, 16.732, 16.732,
          16.732, 16.732, 25.098, 25.098, 25.098, 29.281, 33.464, 41.83, 41.83,
          41.83, 41.83, 37.647, 37.647, 33.464, 33.464, 33.464, 37.647, 37.647,
          33.464, 29.281, 29.281, 29.281, 29.281, 29.281, 25.098, 25.098,
          20.915, 20.915, 12.549, 12.549, 8.366, 8.366, 4.183, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 8.366, 12.549, 16.732, 20.915, 20.915, 20.915,
          29.281, 29.281, 29.281, 33.464, 37.647, 37.647, 41.83, 46.013, 50.196,
          54.379, 58.562, 58.562, 58.562, 62.745, 62.745, 66.928,
          71.11099999999999, 71.11099999999999, 71.11099999999999, 79.477,
          79.477, 83.66, 83.66, 87.84299999999999, 92.026, 96.209, 58.562,
          46.013, 20.915, 8.366, 8.366, 4.183, 4.183, 4.183, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12.549, 20.915, 25.098, 29.281,
          25.098, 20.915, 16.732, 16.732, 16.732, 16.732, 20.915, 25.098,
          20.915, 20.915, 16.732, 12.549, 12.549, 4.183, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ],
      ],
    },
  },
  manual: [
    {
      label: 'Diffuse edema',
      className: 'diffuse_edema__M-Key__single',
      color: '#FFEF6B',
      length: 505.864,
      p1: {
        x: 521,
        y: 702,
      },
      p2: {
        x: 595,
        y: 702,
      },
    },
    {
      label: 'Diffuse edema',
      className: 'diffuse_edema__M-Key__single',
      color: '#FFEF6B',
      length: 61.6,
      p1: {
        x: 558,
        y: 691,
      },
      p2: {
        x: 558,
        y: 713,
      },
    },
    {
      label: 'Floaters',
      className: 'floaters__M-Key__single',
      color: '#A7FFEB',
      length: 82.032,
      p1: {
        x: 999,
        y: 17.5,
      },
      p2: {
        x: 1011,
        y: 17.5,
      },
    },
    {
      label: 'Floaters',
      className: 'floaters__M-Key__single',
      color: '#A7FFEB',
      length: 19.599999999999998,
      p1: {
        x: 1005,
        y: 14,
      },
      p2: {
        x: 1005,
        y: 21,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__1',
      color: '#FF3501',
      length: 321.29200000000003,
      p1: {
        x: 526,
        y: 673,
      },
      p2: {
        x: 573,
        y: 673,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__1',
      color: '#FF3501',
      length: 100.8,
      p1: {
        x: 549.5,
        y: 655,
      },
      p2: {
        x: 549.5,
        y: 691,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__2',
      color: '#FF3501',
      length: 102.54,
      p1: {
        x: 654,
        y: 657.5,
      },
      p2: {
        x: 669,
        y: 657.5,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__2',
      color: '#FF3501',
      length: 53.199999999999996,
      p1: {
        x: 661.5,
        y: 648,
      },
      p2: {
        x: 661.5,
        y: 667,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__3',
      color: '#FF3501',
      length: 136.72,
      p1: {
        x: 617,
        y: 654,
      },
      p2: {
        x: 637,
        y: 654,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__3',
      color: '#FF3501',
      length: 61.6,
      p1: {
        x: 627,
        y: 643,
      },
      p2: {
        x: 627,
        y: 665,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__4',
      color: '#FF3501',
      length: 150.392,
      p1: {
        x: 583,
        y: 652,
      },
      p2: {
        x: 605,
        y: 652,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__4',
      color: '#FF3501',
      length: 72.8,
      p1: {
        x: 594,
        y: 639,
      },
      p2: {
        x: 594,
        y: 665,
      },
    },
    {
      label: 'RPE disruption',
      className: 'rpe_disruption__M-Key__1',
      color: '#6BFF06',
      length: 642.5840000000001,
      p1: {
        x: 425,
        y: 746,
      },
      p2: {
        x: 519,
        y: 746,
      },
    },
    {
      label: 'RPE disruption',
      className: 'rpe_disruption__M-Key__1',
      color: '#6BFF06',
      length: 67.2,
      p1: {
        x: 472,
        y: 734,
      },
      p2: {
        x: 472,
        y: 758,
      },
    },
    {
      label: 'RPE disruption',
      className: 'rpe_disruption__M-Key__2',
      color: '#6BFF06',
      length: 348.636,
      p1: {
        x: 369,
        y: 745,
      },
      p2: {
        x: 420,
        y: 745,
      },
    },
    {
      label: 'RPE disruption',
      className: 'rpe_disruption__M-Key__2',
      color: '#6BFF06',
      length: 72.8,
      p1: {
        x: 394.5,
        y: 732,
      },
      p2: {
        x: 394.5,
        y: 758,
      },
    },
    {
      label: 'Soft drusen',
      className: 'soft_drusen__M-Key__single',
      color: '#E24018',
      length: 375.97999999999996,
      p1: {
        x: 388,
        y: 747,
      },
      p2: {
        x: 443,
        y: 747,
      },
    },
    {
      label: 'Soft drusen',
      className: 'soft_drusen__M-Key__single',
      color: '#E24018',
      length: 61.6,
      p1: {
        x: 415.5,
        y: 736,
      },
      p2: {
        x: 415.5,
        y: 758,
      },
    },
  ],
  isAreaGroupsExpanded: false,
  isVolumeGroupsExpanded: false,
  volumeMeasurementValue: 'mm',
});

export const mockMeasurementsMasks = () => ({
  active: [
    'diffuse_edema__M-Key__single',
    'floaters__M-Key__single',
    'pseudocysts__M-Key__1',
    'pseudocysts__M-Key__2',
    'pseudocysts__M-Key__3',
    'pseudocysts__M-Key__4',
    'rpe_disruption__M-Key__1',
    'rpe_disruption__M-Key__2',
    'soft_drusen__M-Key__single',
  ],
  available: [
    {
      className: 'diffuse_edema',
      label: 'Diffuse edema',
      color: '#FFEF6B',
      opacity: '0.60',
      linear: {
        polygon_max_bb: {
          max_x: 505.864,
          max_x_line: {
            p1: {
              x: 521,
              y: 702,
            },
            p2: {
              x: 595,
              y: 702,
            },
          },
          max_y: 61.6,
          max_y_line: {
            p1: {
              x: 558,
              y: 691,
            },
            p2: {
              x: 558,
              y: 713,
            },
          },
        },
        polygon_max_diameter: 506.6383192140133,
        diameter_line: {
          p1: {
            x: 521,
            y: 701,
          },
          p2: {
            x: 595,
            y: 691,
          },
        },
      },
      'b-scan-area': 0.0132,
      points: [
        588, 691, 587, 692, 586, 692, 585, 693, 583, 693, 580, 696, 576, 696,
        575, 697, 574, 697, 573, 698, 568, 698, 566, 696, 553, 696, 551, 698,
        546, 698, 545, 699, 545, 700, 544, 701, 539, 701, 538, 702, 537, 702,
        536, 703, 526, 703, 524, 701, 521, 701, 523, 703, 524, 703, 526, 705,
        529, 705, 534, 710, 539, 710, 540, 711, 540, 712, 541, 713, 544, 713,
        545, 712, 545, 711, 546, 710, 563, 710, 566, 707, 567, 707, 571, 703,
        573, 705, 578, 705, 578, 703, 583, 698, 590, 698, 591, 697, 592, 697,
        593, 696, 595, 696, 595, 691,
      ],
      pathology_en_face_area: 2.0158,
      pathology_volume: 0.2304,
      cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.1266',
        etdrs_3mm: '0.5531',
        etdrs_6mm: '1.8169',
      },
      not_cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.1266',
        etdrs_3mm: '0.4265',
        etdrs_6mm: '1.2637',
      },
      cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0078',
          nl: '0.0078',
        },
        etdrs_3mm: {
          mm: '0.0464',
          nl: '0.0464',
        },
        etdrs_6mm: {
          mm: '0.2167',
          nl: '0.2167',
        },
      },
      not_cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0078',
          nl: '0.0078',
        },
        etdrs_3mm: {
          mm: '0.0386',
          nl: '0.0386',
        },
        etdrs_6mm: {
          mm: '0.1702',
          nl: '0.1702',
        },
      },
      mask_key: 'diffuse_edema__M-Key__single',
      pathology_number: '',
    },
    {
      className: 'floaters',
      label: 'Floaters',
      color: '#A7FFEB',
      opacity: '0.60',
      linear: {
        polygon_max_bb: {
          max_x: 82.032,
          max_x_line: {
            p1: {
              x: 999,
              y: 17.5,
            },
            p2: {
              x: 1011,
              y: 17.5,
            },
          },
          max_y: 19.599999999999998,
          max_y_line: {
            p1: {
              x: 1005,
              y: 14,
            },
            p2: {
              x: 1005,
              y: 21,
            },
          },
        },
        polygon_max_diameter: 82.46095454213454,
        diameter_line: {
          p1: {
            x: 999,
            y: 19,
          },
          p2: {
            x: 1011,
            y: 16,
          },
        },
      },
      'b-scan-area': 0.0016,
      points: [1004, 14, 999, 19, 1001, 21, 1011, 21, 1011, 16, 1009, 14],
      pathology_en_face_area: 0.968,
      pathology_volume: 0.0177,
      cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.0000',
        etdrs_3mm: '0.0023',
        etdrs_6mm: '0.0113',
      },
      not_cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.0000',
        etdrs_3mm: '0.0023',
        etdrs_6mm: '0.0090',
      },
      cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
        etdrs_3mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
        etdrs_6mm: {
          mm: '0.0002',
          nl: '0.0002',
        },
      },
      not_cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
        etdrs_3mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
        etdrs_6mm: {
          mm: '0.0002',
          nl: '0.0002',
        },
      },
      mask_key: 'floaters__M-Key__single',
      pathology_number: '',
    },
    {
      className: 'pseudocysts',
      label: 'Pseudocysts',
      color: '#FF3501',
      opacity: '0.60',
      linear: {
        polygon_max_bb: {
          max_x: 321.29200000000003,
          max_x_line: {
            p1: {
              x: 526,
              y: 673,
            },
            p2: {
              x: 573,
              y: 673,
            },
          },
          max_y: 100.8,
          max_y_line: {
            p1: {
              x: 549.5,
              y: 655,
            },
            p2: {
              x: 549.5,
              y: 691,
            },
          },
        },
        polygon_max_diameter: 322.5097661529029,
        diameter_line: {
          p1: {
            x: 526,
            y: 672,
          },
          p2: {
            x: 573,
            y: 682,
          },
        },
      },
      'b-scan-area': 0.0223,
      points: [
        541, 655, 540, 656, 540, 657, 539, 658, 536, 658, 529, 665, 529, 670,
        528, 671, 527, 671, 526, 672, 526, 674, 529, 677, 529, 684, 531, 686,
        531, 689, 532, 690, 533, 690, 534, 691, 544, 691, 546, 689, 546, 688,
        548, 686, 551, 686, 558, 679, 563, 679, 568, 684, 571, 684, 573, 682,
        573, 672, 568, 667, 563, 667, 558, 662, 558, 660, 553, 655,
      ],
      pathology_en_face_area: 0.5983,
      pathology_volume: 0.0315,
      cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.2042',
        etdrs_3mm: '0.5983',
        etdrs_6mm: '0.5983',
      },
      not_cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.2042',
        etdrs_3mm: '0.3941',
        etdrs_6mm: '0.0000',
      },
      cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
        etdrs_6mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
      },
      not_cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0184',
          nl: '0.0184',
        },
        etdrs_6mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
      },
      mask_key: 'pseudocysts__M-Key__1',
      pathology_number: '1',
    },
    {
      className: 'pseudocysts',
      label: 'Pseudocysts',
      color: '#FF3501',
      opacity: '0.60',
      linear: {
        polygon_max_bb: {
          max_x: 102.54,
          max_x_line: {
            p1: {
              x: 654,
              y: 657.5,
            },
            p2: {
              x: 669,
              y: 657.5,
            },
          },
          max_y: 53.199999999999996,
          max_y_line: {
            p1: {
              x: 661.5,
              y: 648,
            },
            p2: {
              x: 661.5,
              y: 667,
            },
          },
        },
        polygon_max_diameter: 107.90464123474973,
        diameter_line: {
          p1: {
            x: 654,
            y: 665,
          },
          p2: {
            x: 669,
            y: 653,
          },
        },
      },
      'b-scan-area': 0.0052,
      points: [
        659, 648, 658, 649, 658, 650, 657, 651, 657, 653, 656, 654, 655, 654,
        654, 655, 654, 665, 655, 666, 656, 666, 657, 667, 667, 667, 667, 665,
        668, 664, 668, 663, 669, 662, 669, 653, 664, 648,
      ],
      pathology_en_face_area: 0.5983,
      pathology_volume: 0.0315,
      cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.2042',
        etdrs_3mm: '0.5983',
        etdrs_6mm: '0.5983',
      },
      not_cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.2042',
        etdrs_3mm: '0.3941',
        etdrs_6mm: '0.0000',
      },
      cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
        etdrs_6mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
      },
      not_cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0184',
          nl: '0.0184',
        },
        etdrs_6mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
      },
      mask_key: 'pseudocysts__M-Key__2',
      pathology_number: '2',
    },
    {
      className: 'pseudocysts',
      label: 'Pseudocysts',
      color: '#FF3501',
      opacity: '0.60',
      linear: {
        polygon_max_bb: {
          max_x: 136.72,
          max_x_line: {
            p1: {
              x: 617,
              y: 654,
            },
            p2: {
              x: 637,
              y: 654,
            },
          },
          max_y: 61.6,
          max_y_line: {
            p1: {
              x: 627,
              y: 643,
            },
            p2: {
              x: 627,
              y: 665,
            },
          },
        },
        polygon_max_diameter: 144.7691900923669,
        diameter_line: {
          p1: {
            x: 617,
            y: 665,
          },
          p2: {
            x: 637,
            y: 648,
          },
        },
      },
      'b-scan-area': 0.0061,
      points: [
        627, 643, 623, 647, 623, 648, 620, 651, 620, 653, 619, 654, 618, 654,
        617, 655, 617, 665, 625, 665, 635, 655, 635, 653, 637, 651, 637, 648,
        632, 643,
      ],
      pathology_en_face_area: 0.5983,
      pathology_volume: 0.0315,
      cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.2042',
        etdrs_3mm: '0.5983',
        etdrs_6mm: '0.5983',
      },
      not_cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.2042',
        etdrs_3mm: '0.3941',
        etdrs_6mm: '0.0000',
      },
      cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
        etdrs_6mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
      },
      not_cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0184',
          nl: '0.0184',
        },
        etdrs_6mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
      },
      mask_key: 'pseudocysts__M-Key__3',
      pathology_number: '3',
    },
    {
      className: 'pseudocysts',
      label: 'Pseudocysts',
      color: '#FF3501',
      opacity: '0.60',
      linear: {
        polygon_max_bb: {
          max_x: 150.392,
          max_x_line: {
            p1: {
              x: 583,
              y: 652,
            },
            p2: {
              x: 605,
              y: 652,
            },
          },
          max_y: 72.8,
          max_y_line: {
            p1: {
              x: 594,
              y: 639,
            },
            p2: {
              x: 594,
              y: 665,
            },
          },
        },
        polygon_max_diameter: 156.14657749691523,
        diameter_line: {
          p1: {
            x: 583,
            y: 658,
          },
          p2: {
            x: 605,
            y: 643,
          },
        },
      },
      'b-scan-area': 0.0092,
      points: [
        590, 639, 590, 641, 588, 643, 588, 646, 587, 647, 586, 647, 585, 648,
        585, 651, 583, 653, 583, 658, 585, 660, 585, 662, 588, 662, 589, 663,
        589, 664, 590, 665, 595, 665, 598, 662, 600, 662, 601, 661, 602, 661,
        603, 660, 603, 658, 604, 657, 604, 656, 605, 655, 605, 643, 603, 641,
        602, 641, 600, 639,
      ],
      pathology_en_face_area: 0.5983,
      pathology_volume: 0.0315,
      cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.2042',
        etdrs_3mm: '0.5983',
        etdrs_6mm: '0.5983',
      },
      not_cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.2042',
        etdrs_3mm: '0.3941',
        etdrs_6mm: '0.0000',
      },
      cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
        etdrs_6mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
      },
      not_cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0184',
          nl: '0.0184',
        },
        etdrs_6mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
      },
      mask_key: 'pseudocysts__M-Key__4',
      pathology_number: '4',
    },
    {
      className: 'rpe_disruption',
      label: 'RPE disruption',
      color: '#6BFF06',
      opacity: '1.00',
      linear: {
        polygon_max_bb: {
          max_x: 642.5840000000001,
          max_x_line: {
            p1: {
              x: 425,
              y: 746,
            },
            p2: {
              x: 519,
              y: 746,
            },
          },
          max_y: 67.2,
          max_y_line: {
            p1: {
              x: 472,
              y: 734,
            },
            p2: {
              x: 472,
              y: 758,
            },
          },
        },
        polygon_max_diameter: 644.7824726650065,
        diameter_line: {
          p1: {
            x: 425,
            y: 734,
          },
          p2: {
            x: 519,
            y: 753,
          },
        },
      },
      'b-scan-area': 0.0121,
      points: [
        425, 734, 430, 734, 436, 740, 437, 740, 443, 746, 443, 747, 444, 748,
        444, 749, 448, 753, 448, 755, 449, 756, 449, 757, 450, 758, 472, 758,
        475, 755, 509, 755, 510, 754, 511, 754, 512, 753, 519, 753, 519, 751,
        516, 748, 497, 748, 494, 751, 492, 751, 491, 752, 490, 752, 489, 753,
        475, 753, 474, 752, 473, 752, 472, 751, 465, 751, 462, 748, 457, 748,
        455, 746, 452, 746, 450, 744, 448, 744, 443, 739, 440, 739, 439, 738,
        439, 737, 438, 736, 435, 736, 433, 734,
      ],
      pathology_en_face_area: 0.4759,
      pathology_volume: 0.0091,
      cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.1549',
        etdrs_3mm: '0.3448',
        etdrs_6mm: '0.4751',
      },
      not_cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.1549',
        etdrs_3mm: '0.1899',
        etdrs_6mm: '0.1304',
      },
      cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
        etdrs_6mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
      },
      not_cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0184',
          nl: '0.0184',
        },
        etdrs_6mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
      },
      mask_key: 'rpe_disruption__M-Key__1',
      pathology_number: '1',
    },
    {
      className: 'rpe_disruption',
      label: 'RPE disruption',
      color: '#6BFF06',
      opacity: '1.00',
      linear: {
        polygon_max_bb: {
          max_x: 348.636,
          max_x_line: {
            p1: {
              x: 369,
              y: 745,
            },
            p2: {
              x: 420,
              y: 745,
            },
          },
          max_y: 72.8,
          max_y_line: {
            p1: {
              x: 394.5,
              y: 732,
            },
            p2: {
              x: 394.5,
              y: 758,
            },
          },
        },
        polygon_max_diameter: 353.5597551984671,
        diameter_line: {
          p1: {
            x: 369,
            y: 753,
          },
          p2: {
            x: 420,
            y: 732,
          },
        },
      },
      'b-scan-area': 0.0056,
      points: [
        411, 732, 410, 733, 409, 733, 408, 734, 403, 734, 401, 736, 398, 736,
        397, 737, 397, 738, 396, 739, 393, 739, 384, 748, 381, 748, 380, 749,
        380, 750, 379, 751, 376, 751, 374, 753, 369, 753, 371, 755, 374, 755,
        375, 756, 375, 757, 376, 758, 384, 758, 386, 756, 386, 755, 387, 754,
        387, 753, 388, 752, 388, 751, 392, 747, 392, 746, 393, 745, 395, 745,
        401, 739, 403, 739, 406, 736, 408, 736, 409, 735, 410, 735, 411, 734,
        416, 734, 418, 732, 420, 732,
      ],
      pathology_en_face_area: 0.4759,
      pathology_volume: 0.0091,
      cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.1549',
        etdrs_3mm: '0.3448',
        etdrs_6mm: '0.4751',
      },
      not_cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.1549',
        etdrs_3mm: '0.1899',
        etdrs_6mm: '0.1304',
      },
      cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
        etdrs_6mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
      },
      not_cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0184',
          nl: '0.0184',
        },
        etdrs_6mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
      },
      mask_key: 'rpe_disruption__M-Key__2',
      pathology_number: '2',
    },
    {
      className: 'soft_drusen',
      label: 'Soft drusen',
      color: '#E24018',
      opacity: '0.60',
      linear: {
        polygon_max_bb: {
          max_x: 375.97999999999996,
          max_x_line: {
            p1: {
              x: 388,
              y: 747,
            },
            p2: {
              x: 443,
              y: 747,
            },
          },
          max_y: 61.6,
          max_y_line: {
            p1: {
              x: 415.5,
              y: 736,
            },
            p2: {
              x: 415.5,
              y: 758,
            },
          },
        },
        polygon_max_diameter: 376.4905316206502,
        diameter_line: {
          p1: {
            x: 388,
            y: 755,
          },
          p2: {
            x: 443,
            y: 748,
          },
        },
      },
      'b-scan-area': 0.0185,
      points: [
        416, 736.8461538461539, 413.53846153846155, 739.2307692307693,
        408.61538461538464, 739.2307692307693, 406.15384615384613,
        741.6153846153846, 403.6923076923077, 741.6153846153846,
        401.2307692307692, 744, 398.7692307692308, 744, 396.3076923076923,
        746.3846153846154, 393.84615384615387, 746.3846153846154,
        393.84615384615387, 748.7692307692307, 388.9230769230769,
        753.5384615384615, 388.9230769230769, 755.9230769230769,
        391.38461538461536, 758.3076923076923, 418.46153846153845,
        758.3076923076923, 420.9230769230769, 755.9230769230769,
        423.38461538461536, 755.9230769230769, 425.84615384615387,
        758.3076923076923, 443.0769230769231, 758.3076923076923,
        443.0769230769231, 748.7692307692307, 433.2307692307692,
        739.2307692307693, 430.7692307692308, 739.2307692307693,
        428.3076923076923, 736.8461538461539,
      ],
      pathology_en_face_area: 0.0588,
      pathology_volume: 0.0029,
      cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.0034',
        etdrs_3mm: '0.0588',
        etdrs_6mm: '0.0588',
      },
      not_cumulative_etdrs_pathology_areas: {
        etdrs_1mm: '0.0034',
        etdrs_3mm: '0.0554',
        etdrs_6mm: '0.0000',
      },
      cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
        etdrs_6mm: {
          mm: '0.0315',
          nl: '0.0315',
        },
      },
      not_cumulative_etdrs_pathology_volumes: {
        etdrs_1mm: {
          mm: '0.0131',
          nl: '0.0131',
        },
        etdrs_3mm: {
          mm: '0.0184',
          nl: '0.0184',
        },
        etdrs_6mm: {
          mm: '0.0000',
          nl: '0.0000',
        },
      },
      mask_key: 'soft_drusen__M-Key__single',
      pathology_number: '',
    },
  ],
  unavailable: [],
});

export const mockMeasurementsAutoLines = () => ({
  active: [
    'diffuse_edema__M-Key__single',
    'floaters__M-Key__single',
    'pseudocysts__M-Key__1',
    'pseudocysts__M-Key__2',
    'pseudocysts__M-Key__3',
    'pseudocysts__M-Key__4',
    'rpe_disruption__M-Key__1',
    'rpe_disruption__M-Key__2',
    'soft_drusen__M-Key__single',
  ],
  available: [
    {
      label: 'Diffuse edema',
      className: 'diffuse_edema__M-Key__single',
      color: '#FFEF6B',
      length: 505.864,
      p1: {
        x: 521,
        y: 702,
      },
      p2: {
        x: 595,
        y: 702,
      },
    },
    {
      label: 'Diffuse edema',
      className: 'diffuse_edema__M-Key__single',
      color: '#FFEF6B',
      length: 61.6,
      p1: {
        x: 558,
        y: 691,
      },
      p2: {
        x: 558,
        y: 713,
      },
    },
    {
      label: 'Floaters',
      className: 'floaters__M-Key__single',
      color: '#A7FFEB',
      length: 82.032,
      p1: {
        x: 999,
        y: 17.5,
      },
      p2: {
        x: 1011,
        y: 17.5,
      },
    },
    {
      label: 'Floaters',
      className: 'floaters__M-Key__single',
      color: '#A7FFEB',
      length: 19.599999999999998,
      p1: {
        x: 1005,
        y: 14,
      },
      p2: {
        x: 1005,
        y: 21,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__1',
      color: '#FF3501',
      length: 321.29200000000003,
      p1: {
        x: 526,
        y: 673,
      },
      p2: {
        x: 573,
        y: 673,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__1',
      color: '#FF3501',
      length: 100.8,
      p1: {
        x: 549.5,
        y: 655,
      },
      p2: {
        x: 549.5,
        y: 691,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__2',
      color: '#FF3501',
      length: 102.54,
      p1: {
        x: 654,
        y: 657.5,
      },
      p2: {
        x: 669,
        y: 657.5,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__2',
      color: '#FF3501',
      length: 53.199999999999996,
      p1: {
        x: 661.5,
        y: 648,
      },
      p2: {
        x: 661.5,
        y: 667,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__3',
      color: '#FF3501',
      length: 136.72,
      p1: {
        x: 617,
        y: 654,
      },
      p2: {
        x: 637,
        y: 654,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__3',
      color: '#FF3501',
      length: 61.6,
      p1: {
        x: 627,
        y: 643,
      },
      p2: {
        x: 627,
        y: 665,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__4',
      color: '#FF3501',
      length: 150.392,
      p1: {
        x: 583,
        y: 652,
      },
      p2: {
        x: 605,
        y: 652,
      },
    },
    {
      label: 'Pseudocysts',
      className: 'pseudocysts__M-Key__4',
      color: '#FF3501',
      length: 72.8,
      p1: {
        x: 594,
        y: 639,
      },
      p2: {
        x: 594,
        y: 665,
      },
    },
    {
      label: 'RPE disruption',
      className: 'rpe_disruption__M-Key__1',
      color: '#6BFF06',
      length: 642.5840000000001,
      p1: {
        x: 425,
        y: 746,
      },
      p2: {
        x: 519,
        y: 746,
      },
    },
    {
      label: 'RPE disruption',
      className: 'rpe_disruption__M-Key__1',
      color: '#6BFF06',
      length: 67.2,
      p1: {
        x: 472,
        y: 734,
      },
      p2: {
        x: 472,
        y: 758,
      },
    },
    {
      label: 'RPE disruption',
      className: 'rpe_disruption__M-Key__2',
      color: '#6BFF06',
      length: 348.636,
      p1: {
        x: 369,
        y: 745,
      },
      p2: {
        x: 420,
        y: 745,
      },
    },
    {
      label: 'RPE disruption',
      className: 'rpe_disruption__M-Key__2',
      color: '#6BFF06',
      length: 72.8,
      p1: {
        x: 394.5,
        y: 732,
      },
      p2: {
        x: 394.5,
        y: 758,
      },
    },
    {
      label: 'Soft drusen',
      className: 'soft_drusen__M-Key__single',
      color: '#E24018',
      length: 375.97999999999996,
      p1: {
        x: 388,
        y: 747,
      },
      p2: {
        x: 443,
        y: 747,
      },
    },
    {
      label: 'Soft drusen',
      className: 'soft_drusen__M-Key__single',
      color: '#E24018',
      length: 61.6,
      p1: {
        x: 415.5,
        y: 736,
      },
      p2: {
        x: 415.5,
        y: 758,
      },
    },
  ],
});

export const mockMeasurementsManualLines = () => ({
  active: [
    'line_1680533165421',
    'line_1680533155809',
    'line_1680533128033',
    'line_1680533121922',
    'line_1680533072838',
    'line_1680532942873',
  ],
  available: [
    {
      className: 'line_1680533165421',
      color: '#18FA0F',
      label: 'New line',
      length: 100.23361384286211,
      p1: {
        x: 557.6403615929636,
        y: 689.2139084685647,
      },
      p2: {
        x: 565.6462252626436,
        y: 719.0880903373902,
      },
    },
    {
      className: 'line_1680533155809',
      color: '#E0A4D9',
      label: 'New line',
      length: 391.90902146289005,
      p1: {
        x: 532.9556152781172,
        y: 708.7470273827968,
      },
      p2: {
        x: 590.3309715774901,
        y: 693.8099364483841,
      },
    },
    {
      className: 'line_1680533128033',
      color: '#15DA64',
      label: 'Pseudocysts (W)',
      length: 246.37935708983414,
      p1: {
        x: 528.9526834432771,
        y: 684.6178804887454,
      },
      p2: {
        x: 564.3119146510303,
        y: 663.9357545795586,
      },
    },
    {
      className: 'line_1680533121922',
      color: '#8AAD97',
      label: 'Pseudocysts (H)',
      length: 91.84973545960815,
      p1: {
        x: 543.6301001710237,
        y: 658.1907196047845,
      },
      p2: {
        x: 550.968808534897,
        y: 685.7668874837003,
      },
    },
    {
      className: 'line_1680533072838',
      color: '#C20F84',
      label: 'Soft Druzen (H)',
      length: 322.5097661529028,
      p1: {
        x: 390.18437983549154,
        y: 757.0053211708994,
      },
      p2: {
        x: 436.88525124195786,
        y: 746.6642582163059,
      },
    },
    {
      className: 'line_1680532942873',
      color: '#557839',
      label: 'Soft Druzen (W)',
      length: 67.2,
      p1: {
        x: 416.2034367619513,
        y: 762.7503561456734,
      },
      p2: {
        x: 416.2034367619513,
        y: 738.6212092516222,
      },
    },
  ],
});
