/**
 *
 *
 */

import { createElement, memo } from 'react';

import cn from 'classnames';

import { TextProps } from './Text.types';

import styles from './Text.module.scss';

const Text = memo(
  ({
    tag = 'p',
    className = '',
    color = '',
    align = 'left',
    children,
    elementRef,
    ...props
  }: TextProps): JSX.Element => {
    return createElement(
      tag,
      {
        // dangerouslySetInnerHTML: { __html: children },
        className: cn(className, {
          [styles[color]]: color,
          [styles[align]]: align,
        }),
        ref: elementRef,
        ...props,
      },
      children
    );
  }
);

Text.displayName = 'Text';

export default Text;
